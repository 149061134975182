/* eslint-disable import/prefer-default-export */
import { SitemapSelect } from './SitemapSelector/SitemapSelect';
import { useSelectedSitemap } from './hooks/useSelectedSitemap';
import { useSitemapSearch } from './hooks/useSitemapSearch';
import { useTeamSitemaps } from './hooks/useTeamSitemaps';

export function SitemapSelector() {
  const { isFetching, data } = useTeamSitemaps();
  const [selectedSitemap, setSelectedSitemap] = useSelectedSitemap();
  const [sitemapSearch, setSitemapSearch] = useSitemapSearch();

  return (
    <>
      <h6 className="text-bold text-info mb-3">Choose a Sitemap</h6>
      <SitemapSelect
        sitemaps={data?.sitemaps}
        isLoading={isFetching}
        placeholder="Search…"
        emptyMessage="No sitemaps"
        value={selectedSitemap}
        onChange={setSelectedSitemap}
        inputValue={sitemapSearch}
        onInputChange={setSitemapSearch}
      />
      <p className="text-muted font-weight-bold pt-1 mb-0">
        You can only import pages from completed standard/csv/xml sitemaps
      </p>
      {data?.meta?.pagination?.total_pages > 1 && (
        <p className="text-muted font-weight-bold pt-1 mb-0">
          You are working with the latest {data.sitemaps.length} sitemaps
        </p>
      )}
    </>
  );
}
