import { useSelectedNodes } from './hooks/useSelectedNodes';
import { useSelectedSitemap } from './hooks/useSelectedSitemap';
import { Steps, useImportStore } from './stores/import';

export function ImportFooter() {
  const step = useImportStore((state) => state.step);

  if (step === Steps.CHOOSE_SITEMAP) {
    return <ChooseSitemapButton />;
  }

  if (step === Steps.CHOOSE_PAGES) {
    return <ImportPagesButton />;
  }

  return null;
}

function ChooseSitemapButton() {
  const [sitemap] = useSelectedSitemap();
  const chooseSitemap = useImportStore((state) => state.chooseSitemap);

  return (
    <button
      type="button"
      className="btn btn-primary btn-block py-3"
      disabled={!sitemap}
      title={sitemap ? undefined : 'Choose a sitemap to continue'}
      onClick={chooseSitemap}
    >
      Continue
    </button>
  );
}

function ImportPagesButton() {
  const [nodes] = useSelectedNodes();
  const startImport = useImportStore((state) => state.startImport);

  const selectionSize = nodes?.length || 0;

  const handleImport = () => {
    startImport();
  };

  return (
    <button
      type="button"
      className="btn btn-primary btn-block py-3"
      disabled={selectionSize === 0}
      title="Choose some pages"
      onClick={handleImport}
    >
      Import ({selectionSize}) Pages
    </button>
  );
}

export default ImportFooter;
