import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useShallow } from 'zustand/shallow';

import SearchInput from '../shared/SearchInput';
import { useSitemapPageSearch } from '../../stores/sitemap-page-search';
import { useInputSearch } from '../../stores/input-search';
import { useSitemapTreeMode } from '../../stores/sitemap-tree-mode';

const Container = styled.div`
  i.fas.fa-search {
    color: #737373;
  }

  i.fas.fa-search:hover,
  .isOpen i.fas.fa-search {
    color: #00ffaf;
  }
`;

function SitemapPagesSearchBar({ initialSearchValue = '' }) {
  const inputRef = useRef(null);
  const [isPageSearchOpen, setIsPageSearchOpen] = useSitemapPageSearch(
    useShallow((state) => [state.isOpen, state.setIsOpen])
  );
  const setInputSearchOpen = useInputSearch((state) => state.setIsOpen);
  const [setMode, setGridView] = useSitemapTreeMode(useShallow((state) => [state.setMode, state.setGridView]));

  const onSearchChange = (value) => {
    document.dispatchEvent(new CustomEvent('sitemapPagesSearchValueUpdated', { detail: { searchValue: value } }));
  };

  const onclearHandler = () => {
    onSearchChange('');
    setGridView(false);
    setIsPageSearchOpen(false);
  };

  const handleOpen = () => {
    setMode('grid');
    setInputSearchOpen(true);
    setIsPageSearchOpen(true);
  };

  useEffect(() => {
    if (isPageSearchOpen) {
      setTimeout(() => {
        inputRef.current.focus();
      }, 200);
    }
  }, [isPageSearchOpen]);

  return (
    <Container className="d-flex search-container">
      <SearchInput
        ref={inputRef}
        initialSearchValue={initialSearchValue}
        onClear={onclearHandler}
        onChange={onSearchChange}
        onOpen={handleOpen}
      />
    </Container>
  );
}

export default SitemapPagesSearchBar;
