import styled from 'styled-components';

const IconContainer = styled.div`
  display: inline-block;
  text-align: center;

  i {
    width: 1em;
  }
`;

const NodeIcon = ({ data }) => {
  const { isFolder } = data;

  const title = isFolder ? 'Directory' : 'Page';

  return (
    <IconContainer title={title} className="px-2">
      {isFolder ? <i className="far fa-folder" /> : <i className="far fa-file" />}
    </IconContainer>
  );
};

export default NodeIcon;
