import { useCallback } from 'react';
import Select, { components, createFilter } from 'react-select';
import { createPortal } from 'react-dom';
import { useFloating, autoUpdate, offset } from '@floating-ui/react';

import { selectTheme } from '../../shared/theme';
import Spinnner from '../../shared/Spinner';
import { sitemapLabel } from '../utils';
import { SitemapThumb } from './SitemapThumb';

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    marginBottom: '0.75rem',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: state.theme.colors.primary,
    borderRadius: 0,
    boxShadow: state.isFocused ? `0px 1px 0px ${state.theme.colors.primary}` : undefined,
    '&:hover': {
      borderColor: state.theme.colors.primary,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    color: state.theme.colors.primary,
    paddingLeft: 0,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    top: undefined,
  }),
  option: (provided, state) => ({
    ...provided,
    cursor: state.isDisabled ? 'not-allowed' : 'pointer',
    fontSize: '1rem',
    lineHeight: 1.1875,
    opacity: state.isDisabled ? 0.5 : 1,
    color: state.isSelected ? state.theme.colors.neutral0 : '#CCD9E8',
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    position: 'relative',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.theme.colors.primary,
    pointerEvents: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.theme.colors.neutral800,
  }),
};

const ThinScrollbarsMenuList = ({ children, ...rest }) => {
  return (
    <components.MenuList className="thin-scrollbars" {...rest} maxHeight={undefined}>
      {children}
    </components.MenuList>
  );
};

const SpinnerIndicator = (props) => {
  return <Spinnner small className="mr-3" {...props} />;
};

const SearchIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-search" />
    </components.DropdownIndicator>
  );
};

const floatingOptions = {
  strategy: 'fixed',
  placement: 'left',
  whileElementsMounted: autoUpdate,
  middleware: [offset({ mainAxis: 5, crossAxis: 10 })],
};

const SitemapOption = ({ children, innerRef, ...rest }) => {
  const { refs, floatingStyles } = useFloating(floatingOptions);

  const { data, isFocused } = rest;

  const setInnerRef = useCallback(
    (element) => {
      if (innerRef) {
        innerRef(element);
      }
      refs.setReference(element);
    },
    [innerRef, refs]
  );

  return (
    <components.Option innerRef={setInnerRef} {...rest}>
      {isFocused &&
        data.thumbnail_url &&
        createPortal(
          <SitemapThumb sitemap={data} styles={floatingStyles} innerRef={refs.setFloating} />,
          document.body
        )}
      {children}
    </components.Option>
  );
};

export const selectComponents = {
  IndicatorSeparator: null,
  DropdownIndicator: SearchIndicator,
  MenuList: ThinScrollbarsMenuList,
  LoadingIndicator: SpinnerIndicator,
  Option: SitemapOption,
};

const filter = createFilter({
  ignoreAccents: true,
  ignoreCase: true,
  trim: true,
  matchFrom: 'any',
  stringify: (option) => {
    const { data: sitemap } = option;

    return `${sitemapLabel(sitemap)} ${sitemap.url}`;
  },
});

export function SitemapSelect({
  sitemaps,
  autoFocus,
  placeholder,
  emptyMessage,
  onChange,
  value,
  isLoading,
  inputValue,
  onInputChange,
}) {
  const noMessage = useCallback(() => emptyMessage, [emptyMessage]);

  return (
    <Select
      autoFocus={autoFocus}
      placeholder={placeholder}
      noOptionsMessage={noMessage}
      tabSelectsValue={false}
      getOptionLabel={sitemapLabel}
      getOptionValue={(sitemap) => sitemap.id}
      menuIsOpen
      styles={selectStyles}
      theme={selectTheme}
      onChange={onChange}
      components={selectComponents}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      options={sitemaps}
      isLoading={isLoading}
      defaultValue={value}
      closeMenuOnSelect={false}
      closeMenuOnScroll={false}
      defaultInputValue={inputValue}
      onInputChange={(newInputValue, { action, prevInputValue }) => {
        if (action === 'input-change') {
          onInputChange(newInputValue);
          return newInputValue;
        }

        return prevInputValue;
      }}
      filterOption={filter}
    />
  );
}
