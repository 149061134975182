/* eslint-disable import/prefer-default-export */
import { useRef, useEffect } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import CloseButton from '../shared/CloseButton';
import { Backdrop as BackdropBase, Header, Content as ContentBase, Body as BodyBase } from '../shared/Modal';

const Backdrop = styled(BackdropBase)`
  width: 25vw;
  width: min(100%, max(450px, 25vw));
  left: auto;
`;

const Content = styled(ContentBase)`
  height: auto;
  flex: 1;
  min-height: 0;
`;

const Body = styled(BodyBase)`
  min-height: 0;
`;

const Wrapper = styled.div`
  min-height: 100%;
`;

export function Modal({ children, title, onKeyDown, onClose, open, footer }) {
  const ref = useRef();

  useEffect(() => {
    if (open && ref) {
      ref.current.focus();
    }
  }, [open]);

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      onClose();
    }
  };

  return (
    <Backdrop
      ref={ref}
      className={classNames('p-4 flex-column', { open, 'd-flex': open })}
      tabIndex="-1"
      onKeyDown={handleKeyDown}
    >
      <Wrapper className="d-flex flex-column">
        <Content $footerLess={!footer}>
          <Header className="d-flex align-items-baseline">
            {title}
            <CloseButton onClick={onClose} className="ml-auto pl-1 close-button" />
          </Header>
          <Body className="d-flex flex-column flex-grow-1">{children}</Body>
        </Content>
        {footer && <div className="mt-3">{footer}</div>}
      </Wrapper>
    </Backdrop>
  );
}
