import { useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import { flip, offset, useFloating } from '@floating-ui/react';

import { Label } from './Label';
import { EditableTagList } from './EditableTagList';
import { EmptyTag } from './TagList';

const Backdrop = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: default;
  z-index: 1560;
`;

const PickerContainer = styled.div.attrs({
  className: 'rounded d-inline-flex align-items-center',
})`
  border: 1px solid #5a5959;
  cursor: pointer;
  color: #a3a3a3;
  padding: 0.25rem 0;

  .toggle {
    background: inherit;
    border: 0;
    color: inherit;
    cursor: pointer;
    outline: none;
    margin-left: 0.43rem;
  }
`;

const FloatingContainer = styled.div`
  z-index: 1560;
`;

const findById = (id) => (element) => element.id === id;
const isPresent = (element) => !!element;

const floatingOptions = {
  placement: 'bottom-start',
  middleware: [flip(), offset({ mainAxis: 5, crossAxis: -10 })],
};

function ToggleButton({ isOpen }) {
  const toggleIconClasses = classNames('fa', { 'fa-angle-down': !isOpen, 'fa-angle-up': isOpen });
  const label = isOpen ? 'Close' : 'Open';

  return (
    <div className="d-inline-block ml-auto">
      <button type="button" className="toggle pr-0" aria-label={label}>
        <i className={toggleIconClasses} />
      </button>
    </div>
  );
}

export function TagPickerLabel({ tags, value, charsBreakpoint, placeholder }) {
  const selectedTags = useMemo(() => {
    return value.map((id) => tags.find(findById(id))).filter(isPresent);
  }, [tags, value]);

  return selectedTags.length > 0 ? <Label tags={selectedTags} charsBreakpoint={charsBreakpoint} /> : placeholder;
}

export const TagPicker = ({
  tags,
  value,
  maxLength,
  className,
  onSelect,
  onUnselect,
  onCreate,
  onUpdate,
  onDelete,
  editable,
  placeholder = EmptyTag.name,
  toggle,
  label,
  charsBreakpoint,
  clearable,
  title,
  extraTags,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, floatingStyles, update } = useFloating(floatingOptions);

  const toggleOpen = () => setIsOpen((v) => !v);
  const close = (e) => {
    e.stopPropagation();
    setIsOpen(false);
  };

  const disabled = maxLength && value.length >= maxLength;

  const refreshFloatingPosition = () => {
    update && update();
  };

  return (
    <PickerContainer className={className} onClick={toggleOpen} ref={refs.setReference}>
      {label !== undefined ? (
        label
      ) : (
        <TagPickerLabel tags={tags} value={value} charsBreakpoint={charsBreakpoint} placeholder={placeholder} />
      )}
      {toggle || <ToggleButton isOpen={isOpen} />}
      {isOpen &&
        createPortal(
          <>
            <Backdrop onClick={close} />
            <FloatingContainer onClick={(e) => e.stopPropagation()} ref={refs.setFloating} style={floatingStyles}>
              <EditableTagList
                tags={tags}
                selected={value}
                editable={editable}
                clearable={clearable}
                disabled={disabled}
                title={title}
                extraTags={extraTags}
                onSelect={onSelect}
                onUnselect={onUnselect}
                onCreate={onCreate}
                onUpdate={onUpdate}
                onDelete={onDelete}
                onLayoutChange={refreshFloatingPosition}
              />
            </FloatingContainer>
          </>,
          document.body
        )}
    </PickerContainer>
  );
};

export default TagPicker;
