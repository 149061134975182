import { useQuery } from '@tanstack/react-query';

import { sitemapNodes } from '../api';

export function useSitemapNodes(sitemapId) {
  return useQuery({
    queryKey: ['sitemap', sitemapId, 'pages'],
    queryFn: () => sitemapNodes({ sitemapId }),
    staleTime: 30 * 1000,
    gcTime: 2 * 60 * 1000,
    refetchOnWindowFocus: true,
  });
}

export default useSitemapNodes;
