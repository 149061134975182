import { NodeSelector } from './NodeSelector';
import { SitemapSelector } from './SitemapSelector';
import { useImportStore, Steps } from './stores/import';

export function ImportContent() {
  const step = useImportStore((state) => state.step);

  if (step === Steps.CHOOSE_SITEMAP) {
    return <SitemapSelector />;
  }

  if (step === Steps.CHOOSE_PAGES) {
    return <NodeSelector />;
  }

  throw new Error('Invalid step');
}

export default ImportContent;
