import styled from 'styled-components';

const StyledDescendantCount = styled.div`
  background-color: ${({ $background }) => $background};

  [role='option']:active & {
    background-color: ${({ $activeBackground }) => $activeBackground};
  }
`;

function DescendantCount({ value, getBackground }) {
  const { background, activeBackground } = getBackground();

  return (
    <StyledDescendantCount
      className="d-inline-block font-weight-normal ml-auto position-sticky end-0 px-3"
      $background={background}
      $activeBackground={activeBackground}
    >
      {value}
    </StyledDescendantCount>
  );
}

export default DescendantCount;
