/* global $ */
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { Modal } from '../content-editor/Modal';
import { SitemapProvider } from '../../contexts/SitemapContext';
import { AuthProvider } from '../../contexts/AuthContext';
import { FeaturesProvider } from '../../contexts/FeaturesContext';
import SitemapContentForm from './SitemapContentForm';
import SitemapTitle from './SitemapTitle';
import { PresenceProvider } from '../sitemap-page-content/PresenceContext';
import { sitemapContentKey } from './hooks';

const queryClient = new QueryClient();

export default function SitemapContentEditor({
  sitemapId,
  pageEditionEnabled,
  tagEditionEnabled,
  contentEditionEnabled,
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const editContentRequest = () => {
      if (window.pageContentAccessWarning) {
        window.pageContentAccessWarning();
        return;
      }

      setIsOpen(true);
    };

    const handleContentsChanges = () => {
      queryClient.invalidateQueries({ queryKey: sitemapContentKey(sitemapId), exact: true });
    };

    document.addEventListener('sitemap/EditContent', editContentRequest);
    document.addEventListener('sitemap/ContentsChanges', handleContentsChanges);

    return () => {
      document.removeEventListener('sitemap/EditContent', editContentRequest);
      document.removeEventListener('sitemap/ContentsChanges', handleContentsChanges);
    };
  }, [sitemapId]);

  const close = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    $(document.body).toggleClass('page-content-opened', isOpen);
  }, [isOpen]);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <SitemapProvider sitemapId={sitemapId}>
          <FeaturesProvider features={{ tagEditionEnabled, contentEditionEnabled, pageEditionEnabled }}>
            <PresenceProvider sitemapId={isOpen ? sitemapId : null}>
              {/* FIXME: we should try to use bootstrap modals. I'm not using react-strap or any other library because we have an old version of bootstrap (4.0.0) */}
              <Modal open={isOpen} title={isOpen && <SitemapTitle title="Project Details" />} onClose={close}>
                {isOpen && <ReactQueryDevtools initialIsOpen={false} position="bottom" buttonPosition="bottom-right" />}
                {isOpen && <SitemapContentForm sitemapId={sitemapId} />}
              </Modal>
            </PresenceProvider>
          </FeaturesProvider>
        </SitemapProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}
