import styled, { css } from 'styled-components';

const Checkbox = styled.div`
  display: inline-block;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #979797;
  border-radius: 4px;
  cursor: pointer;

  ${({ $checked }) =>
    $checked &&
    css`
      background: #02ffaf;

      &:after {
        content: '';
        width: 8px;
        height: 8px;
        background: #383838;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        margin: 3px;
        display: block;
      }
    `}
`;

export default Checkbox;
