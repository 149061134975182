import styled from 'styled-components';
import { components } from 'react-select';

const HorizontalScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: fit-content;
`;

const ThinScrollbarsMenuList = ({ children, ...rest }) => {
  return (
    <components.MenuList className="thin-scrollbars h-100" {...rest} maxHeight={undefined}>
      <HorizontalScrollContainer>{children}</HorizontalScrollContainer>
    </components.MenuList>
  );
};

export default ThinScrollbarsMenuList;
