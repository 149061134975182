import classNames from 'classnames';

export function SpinnerIcon({ small, text }) {
  const spinnerClass = classNames('spinner-border text-primary', { 'spinner-border-sm': small });

  return (
    <div className={spinnerClass} role="status">
      <span className="sr-only">{text}</span>
    </div>
  );
}

export default function Spinnner({ className, small }) {
  const containerClass = classNames('d-flex h-100 justify-content-center align-items-center', className);

  return (
    <div className={containerClass}>
      <SpinnerIcon text="Loading..." small={small} />
    </div>
  );
}
