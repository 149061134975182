import styled from 'styled-components';

import { SpinnerIcon } from '../shared/Spinner';

const Container = styled.div.attrs({
  className:
    'position-absolute top-0 bottom-0 start-0 end-0 d-flex flex-column justify-content-center align-items-center',
})`
  z-index: 3;
  background-color: rgba(49, 47, 47, 0.65);
`;

export function ImportingOverlay() {
  return (
    <Container>
      <div className="text-center mb-3">Importing...</div>
      <SpinnerIcon small />
    </Container>
  );
}

export default ImportingOverlay;
