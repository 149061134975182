import { useState, useCallback, useEffect, useMemo } from 'react';
import Select, { components, createFilter } from 'react-select';

import { selectTheme } from '../../shared/theme';
import {
  nodeLabel,
  appendChildren,
  buildTree,
  categorizeNodes,
  initialExpansion,
  removeChildren,
  truncate,
  visibleNodes,
} from '../utils';
import ThinScrollbarsMenuList from './NodeSelect/MenuList';
import NodeOption from './NodeSelect/NodeOption';

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    minHeight: 0,
  }),
  control: (provided, state) => ({
    ...provided,
    marginBottom: '0.75rem',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderColor: state.theme.colors.primary,
    borderRadius: 0,
    boxShadow: state.isFocused ? `0px 1px 0px ${state.theme.colors.primary}` : undefined,
    '&:hover': {
      borderColor: state.theme.colors.primary,
    },
  }),
  menu: (provided, state) => ({
    ...provided,
    position: 'relative',
    boxShadow: 'none',
    color: state.theme.colors.primary,
    paddingLeft: 0,
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    top: undefined,
  }),
  option: (provided, state) => {
    const { treeMode } = state.selectProps;
    const expandable = treeMode && state.data.children;

    const backgroundColor = expandable ? '#414141' : 'transparent';

    return {
      ...provided,
      cursor: state.isDisabled ? 'not-allowed' : 'pointer',
      fontSize: '1rem',
      lineHeight: 1.1875,
      opacity: state.isDisabled ? 0.5 : 1,
      color: '#CCD9E8',
      backgroundColor: state.isFocused ? state.theme.colors.primary25 : backgroundColor,
      whiteSpace: 'nowrap',
      width: '100%',
      position: 'relative',
      fontWeight: expandable ? 'bolder' : undefined,
      ':active': {
        backgroundColor: state.theme.colors.primary50,
      },
      marginBottom: treeMode ? '2px' : undefined,
      paddingRight: treeMode ? 0 : undefined,
    };
  },
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.theme.colors.primary,
    pointerEvents: 'none',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.theme.colors.neutral800,
  }),
};

const SearchIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <i className="fas fa-search" />
    </components.DropdownIndicator>
  );
};

export const selectComponents = {
  IndicatorSeparator: null,
  DropdownIndicator: SearchIndicator,
  MenuList: ThinScrollbarsMenuList,
  Option: NodeOption,
};

const filterOption = createFilter({
  stringify: (option) => `${nodeLabel(option.data.data)} ${option.value}`,
});

export const NodeSelect = ({ nodes, autoFocus, placeholder, emptyMessage, onChange, value }) => {
  const noMessage = useCallback(() => emptyMessage, [emptyMessage]);

  const [searchMode, setSearchMode] = useState(false);
  const tree = useMemo(() => buildTree(nodes), [nodes]);
  const [expansion, setExpansion] = useState(() => initialExpansion(tree));

  const groups = useMemo(() => categorizeNodes(tree), [tree]);
  const options = useMemo(() => visibleNodes(tree, expansion), [tree, expansion]);

  useEffect(() => {
    setExpansion(initialExpansion(tree));
  }, [tree]);

  const toggleNodeExpansion = (node) => {
    setExpansion((prev) => {
      const key = node.id;
      const expanded = prev[key];

      return { ...prev, [key]: !expanded };
    });
  };

  return (
    <Select
      autoFocus={autoFocus}
      placeholder={placeholder}
      noOptionsMessage={noMessage}
      tabSelectsValue={false}
      options={searchMode ? groups : options}
      getOptionLabel={(node) => truncate(nodeLabel(node.data), 45)}
      getOptionValue={(node) => node.id}
      menuIsOpen
      styles={selectStyles}
      theme={selectTheme}
      onChange={(newValue, meta) => {
        // If we are showing a flat list of nodes, notify the change and bail out.
        if (searchMode) {
          onChange(newValue);
          return;
        }

        let selection = newValue;
        if (meta.action === 'select-option') {
          selection = appendChildren(newValue, meta.option);
        }

        if (meta.action === 'deselect-option') {
          selection = removeChildren(newValue, meta.option);
        }

        onChange(selection);
      }}
      components={selectComponents}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      isClearable={false}
      backspaceRemovesValue={false}
      isMulti
      onInputChange={(newInputValue, { action, prevInputValue }) => {
        // Keep input search value after selecting an option (a node).
        if (action === 'input-change') {
          setSearchMode(Boolean(newInputValue));
          return newInputValue;
        }

        return prevInputValue;
      }}
      value={value}
      expandedNodes={expansion}
      onToggleNodeExpansion={toggleNodeExpansion}
      treeMode={!searchMode}
      filterOption={filterOption}
    />
  );
};

export default NodeSelect;
