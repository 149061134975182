/* eslint-disable import/prefer-default-export */
import { NodeSelect } from './NodeSelector/NodeSelect';
import Checkbox from './Checkbox';
import { useFlatImport } from './hooks/useFlatImport';
import { useSelectedNodes } from './hooks/useSelectedNodes';
import { useSelectedSitemap } from './hooks/useSelectedSitemap';
import { useSitemapNodes } from './hooks/useSitemapNodes';

function ImportTypeInput() {
  const [flatImport, setFlatImport] = useFlatImport();

  return (
    <label className="d-flex align-items-center flex-gap-2 cursor-pointer">
      <Checkbox $checked={flatImport} />
      <input
        type="checkbox"
        checked={flatImport}
        onChange={(e) => setFlatImport(e.target.checked)}
        className="d-none"
      />
      Flat Import
    </label>
  );
}

export function NodeSelector() {
  const [selectedSitemap] = useSelectedSitemap();
  const { isFetching, data } = useSitemapNodes(selectedSitemap?.id);
  const [selectedNodes, setSelectedNodes] = useSelectedNodes();

  return (
    <>
      <NodeSelect
        nodes={data}
        isLoading={isFetching}
        placeholder="Search…"
        emptyMessage="No pages found"
        value={selectedNodes}
        onChange={setSelectedNodes}
      />
      <div className="d-flex flex-row align-items-center flex-gap-3">
        <button
          type="button"
          className="btn btn-link pl-0"
          disabled={selectedNodes.length === 0}
          onClick={() => setSelectedNodes([])}
        >
          Deselect all
        </button>
        <ImportTypeInput />
      </div>
    </>
  );
}
