/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import styled from 'styled-components';

import CloseButton from '../shared/CloseButton';
import {
  Backdrop as BackdropBase,
  Header as HeaderBase,
  Content as ContentBase,
  NoScrollingBody,
  Body,
} from '../shared/Modal';

const Backdrop = styled(BackdropBase)`
  &.offcanvas {
    width: 50vw;
    width: min(100%, max(650px, 50vw));
    left: auto;
  }
`;

const Content = styled(ContentBase)`
  ${(props) =>
    !props.$footerLess &&
    `
      padding-bottom: 1rem;
    `}

  ${(props) =>
    props.$borderLess &&
    `
    padding-top: 0.7rem;
    padding-left: 0;
    padding-right: 0;

    .close-button {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
  `}
`;

const Header = styled(HeaderBase)`
  ${(props) =>
    !props.$empty &&
    `
      > .close-button {
        transform: translate(25%, -100%);
      }
    `}
`;

export function Modal({ children, title, onKeyDown, onClose, open, bodyNoScrolling, borderLess, offcanvas, footer }) {
  const ref = useRef();

  useEffect(() => {
    if (open && ref) {
      ref.current.focus();
    }
  }, [open]);

  const handleKeyDown = (e) => {
    if (onKeyDown) {
      onKeyDown(e);
    } else if (e.key === 'Escape') {
      e.stopPropagation();
      onClose();
    }
  };

  return (
    <Backdrop
      ref={ref}
      className={classNames({ open, 'p-5': !offcanvas, 'py-5 px-4': offcanvas, offcanvas })}
      tabIndex="-1"
      onKeyDown={handleKeyDown}
    >
      <Content $borderLess={borderLess} $footerLess={!footer}>
        <Header
          className={classNames('d-flex', { 'align-items-baseline': offcanvas, 'align-items-center': !offcanvas })}
          $empty={!title}
        >
          {title}
          <CloseButton onClick={onClose} className="ml-auto pl-4 close-button" />
        </Header>
        {bodyNoScrolling ? <NoScrollingBody>{children}</NoScrollingBody> : <Body>{children}</Body>}
        {footer && <div className="mt-3 ml-auto">{footer}</div>}
      </Content>
    </Backdrop>
  );
}
