import styled from 'styled-components';

import { Box } from './Box';

// Tinymce plays with z-index of an element in the body to display modals (.tox-tinymce-aux)
// Using z-index 1299 we ensure those modals aren't behind this modal.
// Also lower numbers could make appear sitemap elements on the top of this modal
export const Backdrop = styled.div`
  background: #303030;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1299;
  cursor: auto;

  display: none;
  opacity: 0;

  transition:
    opacity 0.25s,
    display 0.25s allow-discrete;

  filter: drop-shadow(-2px 3px 4px #000000);
  outline: none;

  &.open {
    display: block;
    opacity: 1;
  }

  @starting-style {
    &.open {
      opacity: 0;
    }
  }
`;

export const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  height: 100%;
`;

export const Header = styled.div`
  > * {
    min-width: 0;
  }
`;

export const Body = styled.div.attrs({ className: 'os-scrollbars' })`
  overflow-x: hidden;
  overflow-y: auto;
  margin-right: -1.5rem;
  padding-right: 1.5rem;
`;

export const NoScrollingBody = styled.div`
  width: 100%;
  height: 100%;
`;
