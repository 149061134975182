import { create } from 'zustand';

export const Steps = {
  CHOOSE_SITEMAP: 'CHOOSE_SITEMAP',
  CHOOSE_PAGES: 'CHOOSE_PAGES',
};

export const useImportStore = create((set, get) => ({
  isOpen: false,
  step: Steps.CHOOSE_SITEMAP,
  sitemap: null,
  sitemapSearch: '',
  nodes: [],
  isImporting: false,
  flatImport: false,
  open: () => set({ isOpen: true }),
  close: () => set({ isOpen: false }),

  // actions related to sitemap selection
  setSitemap: (sitemap) => set({ sitemap, nodes: [] }),
  chooseSitemap: () => set({ step: Steps.CHOOSE_PAGES }),
  clearSitemap: () => set({ step: Steps.CHOOSE_SITEMAP }),
  setSitemapSearch: (sitemapSearch) => set({ sitemapSearch }),

  // actions related to nodes selection
  setNodes: (nodes) => set({ nodes }),
  setFlatImport: (flatImport) => set({ flatImport }),

  // submitting actions
  startImport: () => {
    // Block the screen
    set({ isImporting: true });
    // Trigger business logic. See app/javascript/sitemap/tree-changes-tracker.js
    const { nodes: treeNodes, sitemap, flatImport } = get();

    const nodes = treeNodes.map((node) => node.data); // D3 to Plain JS.
    document.dispatchEvent(
      new CustomEvent('treeNodeImportNodes', { detail: { nodes, flatImport, sourceId: sitemap?.id } })
    );
  },
  finishImport: (success) => {
    if (success) {
      // Unlock the screen, reset node selection and Cclose the modal.
      set({ isImporting: false, nodes: [], isOpen: false });
    } else {
      // Unlock the screen.
      set({ isImporting: false });
    }
  },
}));
