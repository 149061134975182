import { nameOrDefault, nodesToTree, parentPresent } from '../../sitemap/utils';
import { depthFirstVisit, getChildren, breadthFirstVisit } from '../../sitemap/tree-renderer/hierarchy';
import { sortCriteria } from '../../sitemap/tree-renderer/utils';

export const nodeLabel = nameOrDefault;

export function sitemapLabel(sitemap) {
  return `${sitemap.nickname || sitemap.url} - v${sitemap.crawl_version}`;
}

export function buildTree(nodes) {
  if (!nodes) {
    return null;
  }

  // Use the same logic we defined to build the sitemap tree.
  const tree = nodesToTree(nodes.filter(parentPresent));
  return tree.sum(() => 1).sort(sortCriteria);
}

/**
 * Group nodes by parent in the directory view.
 * This way we add more context to text search results. Meaning, we can show the parent of each result
 *
 * @param {*} nodes
 */
export function categorizeNodes(tree) {
  if (!tree) {
    return [];
  }

  const groups = [
    {
      label: 'Root',
      options: [tree],
    },
  ];

  breadthFirstVisit(tree, (node) => {
    const children = getChildren(node);

    if (!children) {
      return;
    }

    groups.push({
      label: nodeLabel(node.data),
      options: children,
    });
  });

  return groups;
}

/**
 * Traverse the tree in a depth-first order and return all visible nodes.
 * The returned array does not descendants of collapsed nodes.
 *
 * Note: his method must keep stable references (same object reference for the same node) to prevent
 * flicking when collapsing / expanding a node.
 *
 * React-Select uses indexOf to find the focused option when options change. If we generate new objects
 * on every call the user sees the focus jumping around (from clicked option to first option and back).
 */
export function visibleNodes(tree, expanded) {
  if (!tree) {
    return [];
  }

  const options = [];

  depthFirstVisit(
    tree,
    (node) => {
      options.push(node);
    },
    {
      skipChildren: (node) => !expanded[node.id],
    }
  );

  return options;
}

export function initialExpansion(tree) {
  const result = {};
  if (!tree) {
    return result;
  }

  result[tree.id] = true;

  if (tree.children) {
    tree.children.forEach((child) => {
      result[child.id] = true;
    });
  }

  return result;
}

/**
 * Extend the given selection adding the children of the given node.
 * The returned value as no duplicates.
 */
export function appendChildren(selection, node) {
  const r = new Set(selection);

  depthFirstVisit(node, (n) => {
    r.add(n);
  });

  if (r.size === selection.length) {
    return selection;
  }

  return Array.from(r);
}

/**
 * Remove the children of the given node from the selection.
 */
export function removeChildren(selection, node) {
  const r = new Set(selection);

  depthFirstVisit(node, (n) => {
    r.delete(n);
  });

  if (r.size === selection.length) {
    return selection;
  }

  return Array.from(r);
}

export function truncate(text, maxChars) {
  if (text.length <= maxChars) {
    return text;
  }

  return `${text.slice(0, maxChars - 1)}…`;
}
