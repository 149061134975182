import styled from 'styled-components';
import classNames from 'classnames';

const ExpansionButton = styled.button`
  width: 30px;
  &:not(:hover) {
    color: inherit;
  }
`;

const ButtonPlaceholder = styled.div`
  width: 30px;
`;

const NodeExpansionButton = ({ node, expanded, onClick }) => {
  if (!node.data.parent) {
    // Align root node label with it's children expansion button.
    // It will be only one of this phantom divs. It's easier than to calculate the padding on root's label.
    return <div />;
  }

  if (!node.children) {
    return <ButtonPlaceholder className="px-2 py-0" />;
  }

  return (
    <ExpansionButton
      type="button"
      className="btn btn-link px-2 py-0"
      aria-label="Expand / Collapse"
      title="Expand / Collapse"
      onClick={(e) => {
        e.stopPropagation();
        onClick(node);
      }}
    >
      <i className={classNames('fa', { 'fa-chevron-down': expanded, 'fa-chevron-right': !expanded })} />
    </ExpansionButton>
  );
};

export default NodeExpansionButton;
