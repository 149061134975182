import { useCallback } from 'react';
import { createPortal } from 'react-dom';
import { components } from 'react-select';
import { useFloating, autoUpdate, offset, detectOverflow } from '@floating-ui/react';

import { SitemapPageThumb } from '../SitemapPageThumb';
import Checkbox from '../../Checkbox';
import NodeIcon from './NodeIcon';
import { nodeLabel } from '../../utils';
import NodeExpansionButton from './NodeOption/NodeExpansionButton';
import DescendantCount from './NodeOption/DescendantCount';

const TreeNodeOption = ({ label, title, getBackground, data: node, isSelected, selectProps }) => {
  const { expandedNodes, onToggleNodeExpansion } = selectProps;

  const hasChildren = node.children;
  const marginLeft = node.depth > 1 ? `${Math.max(node.depth - 1, 0)}rem` : undefined;

  return (
    <div className="d-flex flex-row flex-gap-2 align-items-center">
      <Checkbox $checked={isSelected} />
      <NodeExpansionButton node={node} expanded={expandedNodes[node.id]} onClick={onToggleNodeExpansion} />

      <div className="d-inline-block" style={{ marginLeft }} title={title}>
        {label}
      </div>
      {hasChildren && <DescendantCount getBackground={getBackground} value={node.value - 1} />}
    </div>
  );
};

const SearchNodeOption = ({ data: node, isSelected, label, title }) => {
  return (
    <div className="d-flex flex-row flex-gap-2 align-items-center">
      <Checkbox $checked={isSelected} />
      <NodeIcon data={node.data} />
      <div className="d-inline-block" title={title}>
        {label}
      </div>
    </div>
  );
};

/**
 * Propagate background color to descendant count component.
 *
 * Make sure we match the background color on hover and active states.
 *
 * Return a function to avoid unnecessary calculations when background
 * info is not needed (when option does not display descendant information).
 */
const getBackground = (getStyles, props) => () => {
  const styles = getStyles('option', props);

  return {
    background: styles.backgroundColor,
    activeBackground: styles[':active'].backgroundColor,
  };
};

/**
 * Our node menu list has horizontal scroll. In that case option (the reference element) may overflow the list.
 * When that happens, we don't want to place the floating element far from the menu list edges.
 */
const clampToMenuListLeft = () => {
  return {
    name: 'stickToVisibleReferenceLeft',
    async fn(state) {
      const overflow = await detectOverflow(state, {
        elementContext: 'reference',
      });

      return {
        x: state.x + Math.max(overflow.left, 0),
        y: state.y,
      };
    },
  };
};

const floatingOptions = {
  strategy: 'fixed',
  placement: 'left',
  whileElementsMounted: autoUpdate,
  middleware: [clampToMenuListLeft(), offset({ mainAxis: 5, crossAxis: 10 })],
};

const NodeOption = ({ children, innerRef, ...rest }) => {
  const { refs, floatingStyles } = useFloating(floatingOptions);

  const { data: node, isFocused, getStyles } = rest;
  const { data } = node;
  const { isFolder } = data;
  const { treeMode } = rest.selectProps;

  const setInnerRef = useCallback(
    (element) => {
      if (innerRef) {
        innerRef(element);
      }
      refs.setReference(element);
    },
    [innerRef, refs]
  );

  // We truncate the label. For pages we display a thumb tooltip. Let's do something simpler for folders (no thumb).
  const title = isFolder ? nodeLabel(data) : undefined;

  return (
    <components.Option innerRef={setInnerRef} {...rest}>
      {treeMode ? (
        <TreeNodeOption {...rest} label={children} title={title} getBackground={getBackground(getStyles, rest)} />
      ) : (
        <SearchNodeOption {...rest} label={children} title={title} />
      )}

      {!isFolder &&
        isFocused &&
        createPortal(
          <SitemapPageThumb page={data} styles={floatingStyles} innerRef={refs.setFloating} />,
          document.body
        )}
    </components.Option>
  );
};

export default NodeOption;
