import { useQuery } from '@tanstack/react-query';

import { searchOtherSitemaps } from '../api';
import { useSitemap } from '../../../contexts/SitemapContext';

export function useTeamSitemaps() {
  const { sitemapId } = useSitemap();

  return useQuery({
    queryKey: ['sitemap', sitemapId, 'team', 'sitemaps'],
    queryFn: () => searchOtherSitemaps({ sitemapId }),
    staleTime: 60 * 1000,
    gcTime: 15 * 60 * 1000,
  });
}

export default useTeamSitemaps;
