import styled from 'styled-components';

const Container = styled.div`
  background: #737373;
  color: white;
  padding: 2px;
  border-radius: 4px;
  width: 130px;
  z-index: 1300;

  .thumbnail {
    display: block;
    width: 100%;
    min-height: 93px;
    border-radius: 4px;
  }

  .title {
    margin: 0.3rem;
    font-size: 0.625rem;
    line-height: 1.1;
    word-break: break-all;
  }
`;

export const SitemapThumb = ({ sitemap, maxChars = 150, styles, innerRef }) => {
  const title = sitemap.url.length < maxChars ? sitemap.url : `${sitemap.url.slice(0, maxChars - 1)}…`;
  const thumbnailUrl = sitemap.thumbnail_url;
  const version = sitemap.crawl_version;

  return (
    <Container ref={innerRef} className="shadow" style={styles}>
      {thumbnailUrl ? (
        <img className="thumbnail" src={thumbnailUrl} alt={`Thumbnail of sitemap ${title} - v${version}`} />
      ) : (
        <div className="thumbnail" />
      )}
      <div className="title">{title}</div>
    </Container>
  );
};

export default SitemapThumb;
